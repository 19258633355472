import { Box, Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PageHeader from '../../components/PageHeader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Grid from '@mui/material/Unstable_Grid2';
import FavoriteIcon from '@mui/icons-material/Favorite';
import makeRequest from '../../../src/makeRequest';
import CommentIcon from '@mui/icons-material/Comment';
import Insights from './Insights';
import { useDataContext } from '../../context/DataContext';
import ProductChannelStats from './ProductChannelStats';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ProductPostStats from './ProductPostStats';
import { CountCard } from './CountCard';
import GroupedCard from './GroupedCard';

function Dashboard() {
    const { products } = useDataContext();
    const [totalPosts, setTotalPosts] = useState(0);
    const [totalComments, setTotalComments] = useState(0);
    const [productStats, setProductStats] = useState([]);
    const [dynamicCardloading, setDynamicCardLoading] = useState(true);
    const [loadingPosts, setLoadingPosts] = useState(true);
    const [loadingComments, setLoadingComments] = useState(true);
    const [groupedCardLoading, setGroupedCardLoading] = useState(true);
    const [groupedCardData, setGroupedCardData] = useState([]);

    const fetchAllPostCounts = async () => {
        try {
            const allPostCountRes = await makeRequest({
                url: `/api/post/all-post-counts`,
                method: 'GET',
            });
            if (allPostCountRes) {
                const publishedPostCounts = allPostCountRes.productChannelCounts.map(product => {
                    const publishedCount = product.channels.reduce((sum, channel) => {
                        return sum + (channel.postCounts.publishedEntries || 0);
                    }, 0);

                    return {
                        productId: product.productId,
                        productName: product.productName,
                        totalPosts: publishedCount
                    };
                });
                setTotalPosts(allPostCountRes?.groupedStatusCounts?.publishedEntries || 0);
                setProductStats(publishedPostCounts);
                setGroupedCardData(allPostCountRes)
            }
        } catch (error) {
            console.error('Failed to fetch all post count:', error);
            const productStats = products.map((product, index) => {
                return ({
                    productName: product.attributes.name,
                    totalPosts: 0,
                });
            });
            setProductStats(productStats);
        } finally {
            setLoadingPosts(false);
            setLoadingComments(false);
            setGroupedCardLoading(false);
            setDynamicCardLoading(false);
        }
    };

    useEffect(() => {
        fetchAllPostCounts();
    }, []);

    const cardData = [
        {
            loading: loadingPosts,
            title: 'Total Posts',
            value: `${totalPosts || 0}`,
            icon: <FavoriteIcon color="primary" />,
        },
        {
            loading: loadingComments,
            title: 'Total Comments',
            value: `${totalComments || 0}`,
            icon: <CommentIcon color="secondary" />,
        },
        ...productStats.map((stat) => ({
            loading: dynamicCardloading,
            title: stat.productName,
            value: `${stat?.totalPosts || 0}`,
            icon: <PostAddIcon color="action" />,
        })),
    ];

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 8,
                px: 1,
            }}
        >
            <Container maxWidth="xl">
                <PageHeader title="Dashboard" icon={<DashboardIcon />} />

                <Grid container spacing={1} sx={{ mt: 2 }}>
                    {cardData.map((card, index) => (
                        <Grid
                            key={`count-card-${index}`}
                            xs={12}
                            sm={6}
                            lg={3}
                        >
                            <CountCard
                                // difference={16}
                                // positive={false}
                                sx={{ height: '100%' }}
                                title={card.title}
                                value={card.value}
                                loading={card.loading}
                            />
                        </Grid>

                    ))}
                </Grid>

                <Box sx={{ mt: 4 }}>
                    <Grid xs={12} md={6} lg={8}>
                        <GroupedCard
                            loading={groupedCardLoading}
                            title=""
                            list={groupedCardData?.productChannelCounts || []}
                        />
                    </Grid>
                </Box>

                <Box sx={{ mt: 4 }}>
                    <Grid xs={12} md={6} lg={8}>
                        <Insights />
                    </Grid>
                </Box>
                <Box sx={{ mt: 4 }}>
                    <Grid xs={12} md={6} lg={8}>
                        <ProductChannelStats />
                    </Grid>
                </Box>
                <Box sx={{ mt: 4 }}>
                    <Grid xs={12} md={6} lg={8}>
                        <ProductPostStats />
                    </Grid>
                </Box>

            </Container>
        </Box>
    );
}

export default Dashboard;
