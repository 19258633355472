import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SIDE_NAV_WIDTH } from '../theme';
import { SideNav } from '../components/SideNav';
import { TopNav } from '../components/TopNav';


const LayoutRoot = styled('div')(({ theme, isOpen }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: isOpen ? SIDE_NAV_WIDTH : 65
  }
}));

const LayoutContainer = styled('div')(({ theme, isOpen }) => ({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  width: '100%',
}));


export const Layout = (props) => {
  const { children, isAuthenticated } = props;
  const location = useLocation();
  const pathname = location.pathname;
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const [sidebarOpen, setSidebarOpen] = useState(isLargeScreen);

  const handleToggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  if (!isAuthenticated) return;

  return (
    <Box>
      <TopNav isOpen={sidebarOpen} onToggleSidebar={handleToggleSidebar} />
      <SideNav isOpen={sidebarOpen} onToggle={handleToggleSidebar} user={isAuthenticated} />
      <LayoutRoot isOpen={sidebarOpen}>
        <LayoutContainer>
          {children}
        </LayoutContainer>
      </LayoutRoot>
    </Box>
  );
};
