import React from 'react'
import { Box, CircularProgress } from '@mui/material'

function Loader(color = 'primary', size = 20) {
    return (
        <Box component="div" display="flex" justifyContent="center" sx={{ textAlign: 'center' }} width="100%" my={4}>
            <CircularProgress
            // size={size}
            />
        </Box>
    )
}

export default Loader