import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import moment from 'moment';
import ChartCard from './ChartCard';
import makeRequest from '../../../src/makeRequest';


export default function Insights() {
  const initialChartData = { labels: [], series: [] };
  const [loading, setLoading] = useState(false);
  const [chartData, setInsightsChartData] = useState(initialChartData);

  async function fetchPostsPerDayByProduct() {
    const postsResponse = await makeRequest({
      url: `/api/post/all-product-published-post`,
      method: 'GET',
    });
    const groupedByProduct = postsResponse?.posts?.reduce((acc, post) => {
      const product = post.channel.product;
      const productId = product.id;
      const productName = product.name;

      if (!acc[productId]) {
        acc[productId] = {
          productId,
          productName,
          groupedPosts: {}
        };
      }

      const postedDate = post.tm_posted
        ? new Date(post.tm_posted).toISOString().split('T')[0]
        : 'unknown';

      acc[productId].groupedPosts[postedDate] = (acc[productId].groupedPosts[postedDate] || 0) + 1;

      return acc;
    }, {}) || [];

    const result = Object.values(groupedByProduct);
    return result;
  }

  function generateAllDates(postsPerDayByProduct) {
    const minDate = dayjs(
      Math.min(
        ...postsPerDayByProduct.flatMap((productData) =>
          Object.keys(productData.groupedPosts)
            .filter((date) => date !== 'unknown')
            .map((date) => new Date(date).getTime())
        )
      )
    );
    const today = dayjs();

    const dates = [];
    for (let date = minDate; date.isBefore(today) || date.isSame(today); date = date.add(1, 'day')) {
      dates.push(date.format('YYYY-MM-DD'));
    }
    return dates;
  }

  function getInsightsChartData(postsPerDayByProduct, allDates) {
    const chartData = postsPerDayByProduct.map((productData) => {
      const { productName, groupedPosts } = productData;
      const dataForProduct = allDates.map((date) => groupedPosts[date] || 0);

      return {
        name: productName,
        data: dataForProduct,
      };
    });

    return chartData;
  }

  async function fetchProductPublishedPosts() {
    try {
      setLoading(true);
      const postsPerDayByProduct = await fetchPostsPerDayByProduct();
      const allDates = generateAllDates(postsPerDayByProduct);
      const chartData = getInsightsChartData(postsPerDayByProduct, allDates);

      if (chartData.some(series => series.data.length !== allDates.length)) {
        console.error('Error: Mismatch in data lengths for series and labels');
        setInsightsChartData(initialChartData);
        return;
      }
      setInsightsChartData({
        labels: allDates.map(date => moment(date).format('MM/DD/YYYY')),
        series: chartData,
      });
    } catch (error) {
      console.error('Error fetching product stats:', error);
      setInsightsChartData(initialChartData);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchProductPublishedPosts();
  }, []);


  const chartOptions = {
    chart: {
      type: 'line',
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true
      },
      toolbar: {
        autoSelected: 'zoom',
        show: true,
        offsetX: 0,
        offsetY: -38,
      }
    },
    xaxis: {
      type: 'datetime',
    },
    dataLabels: {
      enabled: false
    },
  }

  return (
    <ChartCard
      loading={loading}
      title="Insights"
      subheader="All products published post per day"
      chart={{
        ...chartData,
        options: chartOptions
      }}
      formatTooltip={(value) => (value ? `${value.toFixed(0)} Posts` : value)}
    />
  );
}