import { useState, useEffect } from 'react';
import moment from 'moment';
import { useDataContext } from '../../context/DataContext';
import ChartCard from './ChartCard';
import makeRequest from '../../makeRequest';
import { formatDate } from '../../helpers';

function formatResponseToChartData(response) {
    let allDates = new Set();
    response.channels.forEach(channel => {
        channel.stats.forEach(stat => {
            if (stat.metric_tm) {
                allDates.add(formatDate(stat.metric_tm));
            }
        });
    });

    const labels = Array.from(allDates).sort();

    const series = response.channels.map(channel => {
        const data = labels.map(label => {
            const statsForDate = channel.stats.filter(stat => formatDate(stat.metric_tm) === label);
            return statsForDate.length ? Math.max(...statsForDate.map((stat) => parseInt(stat.value || 0))) : 0;
        });

        return {
            name: channel.display_name,
            data,
        };
    }) || [];

    const formatLabels = labels.map(dateObj => moment(dateObj).format('MM/DD/YYYY'));

    return {
        labels: formatLabels,
        series
    };
}

export default function ProductChannelStats() {
    const { products } = useDataContext();
    const initialChartData = { labels: [], series: [] };
    const [loading, setChannelStatsChartLoading] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(1);
    const [chartData, setChartData] = useState(initialChartData);


    const fetchProductChannelStats = async (productId) => {
        try {
            setChannelStatsChartLoading(true);
            if (!productId) {
                console.error('Product ID is required to fetch channel stats.');
                return;
            }

            const productChannelStatsRes = await makeRequest({
                url: `/api/products/${productId}/channels-stats`,
                method: 'GET',
            });

            if (!productChannelStatsRes || !productChannelStatsRes.channels) {
                console.error('Invalid response from API:', productChannelStatsRes);
                setChartData(initialChartData);
                return;
            }
            const formattedData = formatResponseToChartData(productChannelStatsRes);
            if (formattedData.series.some(series => series.data.length !== formattedData.labels.length)) {
                setChartData(initialChartData);
                return;
            }
            setChartData(formattedData);

        } catch (error) {
            console.error('Failed to fetch product channel stats:', error);
            setChartData(initialChartData);
        } finally {
            setChannelStatsChartLoading(false);
        }
    };

    useEffect(() => {
        fetchProductChannelStats(selectedProduct);
    }, [selectedProduct]);

    const chartOptions = {
        chart: {
            type: 'line',
            zoom: {
                type: 'x',
                enabled: true,
                autoScaleYaxis: true
            },
            toolbar: {
                autoSelected: 'zoom',
                show: true,
                offsetX: 0,
                offsetY: -28,
            }
        },
        xaxis: {
            type: 'datetime',
        },
        dataLabels: {
            enabled: false
        },
    }


    return (
        <ChartCard
            loading={loading}
            title={products.find((p) => (p.id == selectedProduct))?.attributes.name || ''}
            subheader="Connected channels insights"
            chart={{
                ...chartData,
                options: chartOptions
            }}
            selectable
            selectedValue={selectedProduct}
            setSelectedValue={setSelectedProduct}
            optionsList={products.map((p) => ({ id: p.id, name: p.attributes.name }))}
            formatTooltip={(value) => (value ? `${value.toFixed(0)} Followers` : value)}
            chartHeight={385}
        />
    );
}