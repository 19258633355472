import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Container,
    Pagination,
    Stack,
    Typography,
    Unstable_Grid2 as Grid
} from '@mui/material';
import { PostCard } from './PostCard';
import makeRequest from '../../makeRequest';
import MUIModal from '../../components/MUIModal';
import EditPost from './EditPost';
import { removeNonValueFilters } from '../../helpers';
import Loader from '../../components/Loader';


const PostList = () => {
    const [loading, setLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [selectedPost, setSelectedPost] = useState(null);
    const [posts, setPosts] = useState([]);
    const [queryParams, setQueryParams] = useSearchParams();
    const existingParams = Object.fromEntries([...queryParams]);
    const page = existingParams?.page || 1;
    const [pagination, setPagination] = useState({ page, pageCount: 1 });
    const perPage = 12;

    const handleModalClose = () => {
        setSelectedPost(null);
        setShowForm(false);
    }

    const fetchPostData = async () => {
        setIsLoading(true);
        await makeRequest({
            method: 'GET',
            url: `/api/posts?populate=*&publicationState=preview&populate=channel.product&populate=media&sort=createdAt:desc&pagination[page]=${page}&pagination[pageSize]=${perPage}`,
        }).then(respData => {
            setPosts(respData?.data);
            setPagination(respData?.meta?.pagination || { page: 1, pageCount: 1 });
        }).catch(err => {
            console.error(`Error fetching post stats:`, err);
        }).finally(() => {
            setLoading(false);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        fetchPostData();
    }, [page]);

    const updateQueryParams = (params) => {
        const nParams = removeNonValueFilters(Object.assign({}, existingParams, params));
        setQueryParams(nParams);
    }
    const handlePageChange = (event, value) => {
        updateQueryParams({ page: value })
    };

    return (
        <>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 4
                }}
            >
                <Container maxWidth="xl">
                    { loading && <Loader/> }
                    {posts.length > 0 && <>
                        <Stack spacing={1}>
                            <Grid
                                container
                                spacing={2}
                            >
                                {posts.map((postData) => (
                                    <Grid
                                        xs={12}
                                        md={6}
                                        lg={4}
                                        key={postData.id}
                                    >
                                        <PostCard 
                                            postData={postData} 
                                            dataType="posts"
                                            onEdit={(eData)=>{ setSelectedPost(eData.id); setShowForm(true);}}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                                py={4}
                            >
                                <Pagination
                                    count={pagination.pageCount}
                                    page={pagination.page}
                                    onChange={handlePageChange}
                                    size="small"
                                />
                            </Box>
                        </Stack>
                    </>}
                </Container>
            </Box>
            {selectedPost && <MUIModal
                maxWidth='md'
                title='Edit Post'
                open={showForm}
                handleClose={handleModalClose}
            >
                <EditPost pId={selectedPost} />
            </MUIModal>}
        </>
    );
}


export default PostList;
