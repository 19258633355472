import React from "react";
import ReactDOM from "react-dom/client";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import "./index.css";
import App, { AuthRedirect } from "./App";
import AuthProvider from "./components/AuthProvider/AuthProvider";
import { BrowserRouter as Router } from "react-router-dom";
import { DataProvider } from "./context/DataContext";
import { createTheme } from "./theme";
import "simplebar-react/dist/simplebar.min.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
const defaultTheme = createTheme();
root.render(
  <React.StrictMode>
    <ThemeProvider theme={defaultTheme}>
      <AuthProvider>
        <DataProvider>
          <Router>
            <AuthRedirect />
            <App />
          </Router>
        </DataProvider>
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>
);
