import React, { useState } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { Divider, IconButton } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';


const options = ['Today','Last 24 Hours', 'Last Week', 'Last Month', 'Date Between'];

export default function FilterButtonGroup({ onPeriodChange }) {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [open, setOpen] = useState(false);
    const anchorRef = React.useRef(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleClick = () => {
        if (options[selectedIndex] === 'Date Between') {
            setOpen((prevOpen) => !prevOpen);
        } else {
            onPeriodChange(options[selectedIndex].toLowerCase());
        }
    };

    const handleMenuItemClick = (index) => {
        setSelectedIndex(index);
        if (options[index] !== 'Date Between') {
            onPeriodChange(options[index].toLowerCase());
            setOpen(false);
        }
    };

    const handleDateRangeChange = () => {
        if (startDate && endDate) {
            onPeriodChange({ 
                startDate: startDate.format('YYYY-MM-DD'), 
                endDate: endDate.format('YYYY-MM-DD')
            });
            setOpen(false);
        }
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };


    return (
        <Box display="flex" flexDirection="column" alignItems="center" position="relative">
            <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
                <Button onClick={handleClick}>{options[selectedIndex]}</Button>
                <Button
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select period"
                    aria-haspopup="menu"
                    onClick={() => setOpen((prevOpen) => !prevOpen)}
                >
                    ▼
                </Button>
            </ButtonGroup>
            <Popper
                open={open}
                anchorEl={anchorRef?.current}
                role={undefined}
                transition
                disablePortal
                placement="bottom"
                sx={{
                    position: 'absolute !important',
                    top: '41px !important',
                    left: 0,
                    zIndex: 999
                }}
            >
                {({ TransitionProps }) => {
                    return (
                        <Paper {...TransitionProps} 
                            sx={{ width: 190 }}
                            elevation={2}
                        >
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu">
                                    {selectedIndex !== 4 && (options.map((option, index) => (
                                        <MenuItem
                                            key={option}
                                            selected={index === selectedIndex}
                                            onClick={() => handleMenuItemClick(index)}
                                            sx={{
                                                borderTop: index == 4 ?'1px solid rgba(224, 224, 224, 1)': 'none'
                                            }}
                                        >
                                            {option}
                                        </MenuItem>
                                    )))}
                                    {selectedIndex === 4 && (
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Box padding={2} display="flex" flexDirection="column" gap={2}>
                                                <IconButton onClick={() => { 
                                                    setStartDate(null);
                                                    setEndDate(null);
                                                    handleMenuItemClick(0);
                                                }}
                                                    sx={{ position: 'absolute', left: 8, top: 2 }}
                                                >
                                                    <NavigateBeforeIcon/>
                                                </IconButton>
                                                <Box sx={{mt:3}}>
                                                    <DatePicker
                                                        label="Start Date"
                                                        value={startDate}
                                                        onChange={(newValue) => setStartDate(newValue)}
                                                        renderInput={(params) => <TextField {...params}/>}
                                                        
                                                    />
                                                </Box>
                                                <Box>
                                                    <DatePicker
                                                        label="End Date"
                                                        value={endDate}
                                                        onChange={(newValue) => setEndDate(newValue)}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                </Box>
                                                <Button
                                                    variant="contained"
                                                    onClick={handleDateRangeChange}
                                                    disabled={!startDate || !endDate}
                                                >
                                                    Apply Range
                                                </Button>
                                            </Box>
                                        </LocalizationProvider>
                                    )}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    )
                }}
            </Popper>
        </Box>
    );
}
