import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDataContext } from '../../context/DataContext';
import ChartCard from './ChartCard';
import makeRequest from '../../makeRequest';
import { formatDate } from '../../helpers';

function formatProductAllPostChartData(data) {
    const groupByDate = (entries, dateField) => {
        return entries.reduce((acc, entry) => {
            if (entry[dateField]) {
                const date = formatDate(entry[dateField]);
                acc[date] = (acc[date] || 0) + 1;
            }
            return acc;
        }, {});
    };

    const draftGroups = groupByDate(data.draftEntries, "tm_scheduled");
    const waitingGroups = groupByDate(data.waitingEntries, "tm_scheduled");
    const publishedGroups = groupByDate(data.publishedEntries, "tm_posted");
    const errorGroups = groupByDate(data.errorEntries, "tm_scheduled");

    const allDates = new Set([
        ...Object.keys(draftGroups),
        ...Object.keys(waitingGroups),
        ...Object.keys(publishedGroups),
        ...Object.keys(errorGroups),
    ]);

    const sortedDates = Array.from(allDates).sort();

    const series = [
        {
            name: "Draft",
            data: sortedDates.map((date) => draftGroups[date] || 0),
        },
        {
            name: "Waiting",
            data: sortedDates.map((date) => waitingGroups[date] || 0),
        },
        {
            name: "Published",
            data: sortedDates.map((date) => publishedGroups[date] || 0),
        },
        {
            name: "Error",
            data: sortedDates.map((date) => errorGroups[date] || 0),
        },
    ];

    return {
        labels: sortedDates.map(dateObj => moment(dateObj).format('MM/DD/YYYY')),
        series,
    };

}

export default function ProductPostStats() {
    const { products } = useDataContext();

    const [loading, setLoading] = useState(false);
    const initialChartData = { labels: [], series: [] };
    const [chartData, setChartData] = useState(initialChartData);
    const [selectedProduct, setSelectedProduct] = useState(1);


    const fetchProductAllPosts = async (productId) => {
        try {
            setLoading(true);
            const productAllPostRes = await makeRequest({
                url: `/api/post/product-all-posts?product=${productId}`,
                method: 'GET',
            });
            if (!productAllPostRes) {
                console.error('Invalid API response:', productAllPostRes);
                setChartData(initialChartData);
                return;
            }

            const modifiedChartData = formatProductAllPostChartData(productAllPostRes);
            if (modifiedChartData.series.some(series => series.data.length !== modifiedChartData.labels.length)) {
                setChartData(initialChartData);
                return;
            }

            setChartData(modifiedChartData);
        } catch (error) {
            console.error('Failed to fetch product all post:', error);
            setChartData(initialChartData);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProductAllPosts(selectedProduct);
    }, [selectedProduct]);

    const chartOptions = {
        chart: {
            type: 'line',
            zoom: {
                type: 'x',
                enabled: true,
                autoScaleYaxis: true
            },
            toolbar: {
                autoSelected: 'zoom',
                show: true,
                offsetX: 0,
                offsetY: -28,
            }
        },
        xaxis: {
            type: 'datetime',
        },
        dataLabels: {
            enabled: false
        },
    }

    return (
        <ChartCard
            loading={loading}
            title={products.find((p) => (p.id == selectedProduct))?.attributes.name || ''}
            subheader="product post by status"
            chart={{
                ...chartData,
                options: chartOptions
            }}
            selectable
            selectedValue={selectedProduct}
            setSelectedValue={setSelectedProduct}
            optionsList={products.map((p) => ({ id: p.id, name: p.attributes.name }))}
            formatTooltip={(value) => (value ? `${value.toFixed(0)} Posts` : value)}
            chartHeight={385}
        />
    );
}
