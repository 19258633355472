import React, { useMemo, useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import makeRequest from '../makeRequest';
import * as moment from 'moment';
import Box from '@mui/material/Box';
import FilterButtonGroup from './FilterButtonGroup';
import { Button, Typography, Tooltip as MUITooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import MUILoader from './MUILoader';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';



const CustomizedLabel = (props) => {
    const { x, y, stroke, value } = props;

    return (
        <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
            {value}
        </text>
    );
}


const CustomizedAxisTick = (props) => {
    const { x, y, stroke, payload } = props;

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={10} textAnchor="end" fill="#666" transform="rotate(-35)">
                {payload.value}
            </text>
        </g>
    );
}


const PostStatsChart = ({ postId, postUrl }) => {

    const [loading, setLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const initialPreriod = 'today';
    const [period, setPeriod] = useState(initialPreriod);

    /*
    const formatDataForChart = (likeData, commentData) => {
        const allTimestamps = new Set([
            ...likeData.map(item => item.time),
            ...commentData.map(item => item.time)
        ]);

        const formattedData = Array.from(allTimestamps).map(time => {
            const likeEntry = likeData.find(item => item.time === time);
            const commentEntry = commentData.find(item => item.time === time);
            return {
                time: moment(time).format('YYYY-MM-DD'),
                // time:  moment(time).format('YYYY-MM-DD hh:mm A'),
                // time: new Date(time).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }),
                like: likeEntry ? likeEntry.count : 0,
                comment: commentEntry ? commentEntry.count : 0
            };
        });

        return formattedData.sort((a, b) => new Date(a.time) - new Date(b.time));
    };

    const formatData = (cData) => {
        const allDates = new Set([...cData.map(item => item.attributes.createdAt.split("T")[0])]);
        console.log('allDates: ', allDates);

        const likeData = cData.filter(item => item.attributes.metric_name === 'like').map(item => ({
            time: item.attributes.createdAt.split("T")[0],
            count: item.attributes.Count
        }));

        const commentData = cData.filter(item => item.attributes.metric_name === 'comment').map(item => ({
            time: item.attributes.createdAt.split("T")[0],
            count: item.attributes.Count
        }));
        console.log('likeData: ', likeData);
        console.log('commentData: ', commentData);

        const formattedData = Array.from(allDates).map(time => {
            const likeEntry = likeData.find(item => item.time === time);
            const commentEntry = commentData.find(item => item.time === time);
            return {
                time,
                like: likeEntry ? likeEntry.count : 0,
                comment: commentEntry ? commentEntry.count : 0
            };
        });

        return formattedData.sort((a, b) => new Date(a.time) - new Date(b.time));
    }

        const likeData = data.filter(item => item.metric_name === 'like').map(item => ({
            time: moment(item.metric_tm).format('YYYY-MM-DD hh:mm A'),
            // time: new Date(item.metric_tm).getTime(),
            count: item.Count
        }));
    
        const commentData = data.filter(item => item.metric_name === 'comment').map(item => ({
            time: moment(item.metric_tm).format('YYYY-MM-DD hh:mm A'),
            // time: new Date(item.metric_tm).getTime(),
            count: item.Count
        }));
    
        const chartData = formatDataForChart(likeData, commentData);
        // const chartData = formatData(data);
        */


    const chartDataMap = data.reduce((acc, entry) => {
        const date = entry.attributes.createdAt.split("T")[0];
        const metricType = entry.attributes.metric_name;
        const count = entry.attributes.Count;

        const key = `${date}-${metricType}`;

        if (acc[key]) {
            acc[key].Count = count;
        } else {
            acc[key] = { date, [metricType]: count };
        }

        return acc;
    }, {});

    const chartData = Object.values(chartDataMap).reduce((acc, entry) => {
        const existingDateEntry = acc.find((item) => item.date === entry.date);

        if (existingDateEntry) {
            existingDateEntry[Object.keys(entry)[1]] = entry[Object.keys(entry)[1]];
        } else {
            acc.push(entry);
        }

        return acc;
    }, []);

    // console.log('chartData: ', chartData);

    const handleDurationSelect = (duration) => {
        if (typeof duration === 'object') {
            setPeriod(null)
            setStartDate(duration.startDate);
            setEndDate(duration.endDate);

        } else {
            const periodState = {
                'Today':'today',
                'last 24 hours': 'last24hours',
                'last week': 'inWeek',
                'last month': 'inMonth'
            };
            setStartDate(null);
            setEndDate(null);
            setPeriod(periodState[duration]);
        }
    }

    /*
        useEffect(() => {
            if (!postId) return;
    
            let url = `/api/posts/grouped-by-metric-chart?postId=${postId}`;
    
            if(startDate && endDate){
                url +=`&startDate=${startDate}&endDate=${endDate}`;
            }
            
            if(period){
                url +=`&period=${period}`;
            }
    
            const fetchPostStats = async () => {
                await makeRequest({
                    method: 'GET',
                    url,
                }).then(respData => {
                    setData(respData);
                }).catch(err => {
                    console.error(`Error fetching post stats:`, err);
                }).finally(() => {
                    setLoading(false);
                });
            };
    
            fetchPostStats();
        }, [postId, period, startDate, endDate]);
    */
    // useEffect(() => {
    //     if(!data) return ;

    //     if(startDate && endDate){
    //         const dateRangeData = dateBetweenChartData(data, { startDate,endDate });
    //         console.log('dateRangeData: ', dateRangeData);
    //     }

    // }, [data, startDate, endDate]);


    const getDateFromPeriod = (duration) => {
        const now = new Date();
        switch (duration) {
            case 'today':
                // return new Date(now.getFullYear(), now.getMonth(), now.getDate()); // Start of today
                const startOfDay = new Date(now);
                startOfDay.setHours(0, 0, 0, 0); // Reset to start of the day
                return startOfDay;
            case 'last24hours':
                return new Date(now.getTime() - 24 * 60 * 60 * 1000); // 24 hours ago
            case 'inWeek':
                return new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000); // 7 days ago
            case 'inMonth':
                return new Date(now.setMonth(now.getMonth() - 1)); // 1 month ago
            default:
                throw new Error('Invalid period specified');
        }
    };


    useEffect(() => {
        if (!postId) return;

        let url = `/api/post-stats?publicationState=preview&sort=createdAt:asc&populate[post][fields][0]=content&populate[post][fields][1]=tm_scheduled&populate[post][fields][2]=tm_posted&populate[post][fields][3]=channel_post_id&populate[post][fields][4]=status&filters[post][id][$eq]=${postId}&pagination[start]=1&pagination[limit]=1000`;

        if (startDate && endDate) {
            url += `&filters[createdAt][$gte]=${startDate}&filters[createdAt][$lte]=${endDate}`;
        }

        if (period) {
            const durationStartDate = getDateFromPeriod(period).toISOString();
            url += `&filters[createdAt][$gte]=${durationStartDate}`;

            if (period === 'today') {
                const endOfDay = new Date(durationStartDate);
                endOfDay.setHours(23, 59, 59, 999); // End of the day
                url += `&filters[createdAt][$lte]=${endOfDay.toISOString()}`;
            }
        }

        const fetchPostStats = async () => {
            setIsLoading(true);
            await makeRequest({
                method: 'GET',
                url,
            }).then(respData => {
                // console.log('respData: ', respData);
                setData(respData?.data);
            }).catch(err => {
                console.error(`Error fetching post stats:`, err);
            }).finally(() => {
                setLoading(false);
                setIsLoading(false);
            });
        };

        fetchPostStats();
    }, [postId, period, startDate, endDate]);


    return (
        <Box>
            {loading ? <Box display="flex" justifyContent="center" py={2}>
                <CircularProgress color='primary' sx={{ mb: 0 }} />
            </Box> : (<>
                {!loading && isLoading && <MUILoader open />}
                <Box display="flex" alignItems="center" justifyContent="space-between" py={2}>
                    <FilterButtonGroup onPeriodChange={handleDurationSelect} />
                    {/* <Button variant="contained" onClick={() => window.open(postUrl, '_blank')}>View Post</Button> */}
                    <MUITooltip title="View Post">
                        <IconButton variant="contained" onClick={() => window.open(postUrl, '_blank')}>
                            <VisibilityIcon />
                        </IconButton>
                    </MUITooltip>
                </Box>

                {chartData.length > 0 ? (<ResponsiveContainer width="100%" height={400}>
                    <LineChart
                        width={500}
                        height={300}
                        data={chartData}
                        margin={{
                            top: 5, right: 30, left: 0, bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            // dataKey="time" 
                            dataKey="date"
                        // tick={<CustomizedAxisTick />}
                        />
                        <YAxis
                            allowDecimals={false}
                        />
                        <Tooltip />
                        <Legend />
                        <Line
                            type="monotone"
                            dataKey="comment"
                            stroke="#8884d8"
                        // label={<CustomizedLabel />} 
                        />
                        <Line
                            type="monotone"
                            dataKey="like"
                            stroke="#82ca9d"
                            activeDot={{ r: 8 }}
                        // label={<CustomizedLabel />} 
                        />
                    </LineChart>
                </ResponsiveContainer>) : (
                    <Typography align='center' py={15} variant="h6" gutterBottom>
                        No data available for the selected duration to build the chart view.
                    </Typography>
                )}
            </>)}
        </Box>
    );
};

export default PostStatsChart;
