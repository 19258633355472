import React, { useState, useEffect } from 'react';
import { Dropdown, Button, Menu } from 'antd';
import { Box } from '@mui/material';

const CustomToolbar = ({ label, onNavigate, onView, filterStatus = '', setFilterStatus, view, statusOptions = [] }) => {
  const [viewLabel, setViewLabel] = useState('View');
  const statusList = statusOptions.map(sItem => ({ ...sItem, key: sItem.label }));
  const buttonStyle = { width: '80px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '4px' };
  const filterBtnColor = statusOptions.find(fItem =>fItem.value === filterStatus.toLowerCase())?.backgroundColor || '#FFF';

  useEffect(() => {
    if (view === 'month') setViewLabel("Monthly");
    else if (view === 'week') setViewLabel("Weekly");
    else if (view === 'day') setViewLabel("Daily");
  }, [view]);

  const labelStyle = {
    fontSize: '1.5rem',
    // fontWeight: 'bold',          
    color: '#333',
    // textShadow: '1px 1px 2px rgba(0,0,0,0.2)',  
    margin: '0 auto'
  };
  const filterMenu = (
    <Menu
      onClick={(e) => setFilterStatus(e.key)}
      items={[
        { key: '', label: 'All', backgroundColor: '#FFF' },
        ...statusList
      ]}
    />
  );

  const viewMenu = (
    <Menu
      onClick={(e) => {
        const selectedView = e.key;
        onView(selectedView);
        setViewLabel(e.item.props.children);
      }}
      items={[
        { key: 'month', label: 'Monthly' },
        { key: 'week', label: 'Weekly' },
        { key: 'day', label: 'Daily' },
      ]}
    />
  );

  return (
    <div className="rbc-toolbar" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Dropdown overlay={viewMenu} trigger={['click']}>
        <Button style={buttonStyle}>
          {viewLabel}
        </Button>
      </Dropdown>
      <div style={{ display: 'flex', width:'calc(100% - 1500px)'}}></div>
      <span className="rbc-toolbar-label" style={labelStyle}>{label}</span>

      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <button type="button" onClick={() => onNavigate('PREV')} style={{ marginRight: 6 }}>
          {'<<'}
        </button>
        <button type="button" onClick={() => onNavigate('TODAY')} style={{ marginRight: 6 }} >
          Today
        </button>
        <button type="button" onClick={() => onNavigate('NEXT')} style={{ marginRight: 6 }}>
          {'>>'}
        </button>
        <Dropdown overlay={filterMenu} trigger={['click']}>
          <Button style={{...buttonStyle, color: !filterStatus?'#000':"#FFF", backgroundColor: filterBtnColor}}>
            <span>{filterStatus || "Filter"}</span>
          </Button>
        </Dropdown>
      </Box>
    </div>
  );
};

export default CustomToolbar;
