export const sampleData = {
    labels: [
        '01/01/2023',
        '02/01/2023',
        '03/01/2023',
        '04/01/2023',
        '05/01/2023',
        '06/01/2023',
        '07/01/2023',
        '08/01/2023',
        '09/01/2023',
        '10/01/2023',
        '11/01/2023',
    ],
    series: [
        {
            name: 'Kazhutha',
            // type: 'column',
            type: 'line',
            fill: 'solid',
            data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
        },
        {
            name: 'FamiliConnect',
            // type: 'area',
            // fill: 'gradient',
            type: 'line',
            fill: 'solid',
            data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
        },
        {
            name: 'Focuzwiz',
            type: 'line',
            fill: 'solid',
            data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
        },
        {
            name: 'Scamyodha',
            type: 'line',
            fill: 'solid',
            data: [38, 28, 25, 30, 45, 35, 74, 52, 59, 56, 89],
        },
    ],
}