import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import Loader from '../../components/Loader';

export default function GroupedCard({ loading, title, subheader, list, ...other }) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />
      {loading ? <Loader /> :
      (<Box
        sx={{
          p: 3,
          gap: 2,
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
        }}
      >
        
        {list.map((product) => (
          <Paper
            key={product.productId}
            variant="outlined"
            sx={{ py: 2.5, px: 2, textAlign: 'center' }}
          >

            <Typography variant="h6">{product.productName}</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1 }}>
              Total Posts: {product.totalPosts}
            </Typography>

        
            {product.channels.length > 0 ? (
              product.channels.map((channel) => (
                <Box
                  key={channel.channel_id}
                  sx={{
                    mt: 2,
                    p: 1,
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: 1,
                    textAlign: 'center',
                  }}
                >
                  <Typography variant="subtitle2">
                    {channel.display_name} ({channel.channel_type})
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Total Posts: {channel.totalPosts}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Draft: {channel.postCounts.draftEntries || 0} | Waiting: {channel.postCounts.waitingEntries || 0} | Published: {channel.postCounts.publishedEntries || 0 } | Error: {channel.postCounts.errorEntries || 0}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography variant="body2" sx={{ color: 'text.secondary', mt: 2 }}>
                No Channels Available
              </Typography>
            )}
          </Paper>
        ))}
      </Box>)}
    </Card>
  );
}

GroupedCard.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};
