import React, { useState, useRef, useEffect, Fragment } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const NextArrow = ({ onClick, disabled }) => {
    return (
        !disabled && <IconButton
            onClick={onClick}
            disabled={disabled}
            sx={{
                position: 'absolute',
                top: '50%',
                right: '-6px',
                transform: 'translateY(-50%)',
                color: '#FFF',
                backgroundColor: 'rgba(0,0,0,0.5) !important',
                zIndex: 1,
            }}
        >
            <ChevronRightIcon />
        </IconButton>
    );
};

const PrevArrow = ({ onClick, disabled }) => {
    return (
        !disabled && <IconButton
            onClick={onClick}
            disabled={disabled}
            sx={{
                position: 'absolute',
                top: '50%',
                left: '-6px',
                transform: 'translateY(-50%)',
                color: '#FFF',
                backgroundColor: 'rgba(0,0,0,0.5) !important',
                zIndex: 1,
            }}
        >
            <ChevronLeftIcon />
        </IconButton>
    );
};

const UploadFiles = ({ existingMedia = [], onFilesChange, onRemovedFiles }) => {
    const [files, setFiles] = useState([]);
    const [removedFileIds, setRemovedFileIds] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(0);
    const fileInputRef = useRef(null);

    useEffect(() => {
        if (existingMedia.length === 0) return;

        const initialFiles = existingMedia.map((media) => {
            let preview = media?.attributes?.formats?.thumbnail?.url || media.attributes.url
            if (media?.mime?.startsWith('video/')) {
                preview = media.attributes.url;
            }

            return ({
                id: media.id,
                file: null,
                preview,
                name: media.attributes.name || '',

            })
        });
        setFiles(initialFiles);
    }, [existingMedia]);


    const handleFileSelect = (event) => {
        const selectedFiles = Array.from(event.target.files);
        const supportedFiles = selectedFiles.filter((file) =>
            file.type.startsWith('image/') || file.type.startsWith('video/')
        );
        const previewFiles = supportedFiles.map((file) => {
            return ({
                id: null,
                file,
                preview: URL.createObjectURL(file),
                name: file.name || ''
            })
        });
        setFiles((prevFiles) => {
            const updatedFiles = [...previewFiles, ...prevFiles];
            setCurrentSlide(previewFiles.length - 1); 
            return updatedFiles;
        });
        onFilesChange([...supportedFiles, ...files.map((f) => f.file).filter(Boolean)]);
        event.target.value = null;
    };

    const handleFileDrop = (event) => {
        event.preventDefault();
        const droppedFiles = Array.from(event.dataTransfer.files);
        const supportedFiles = droppedFiles.filter((file) =>
            file.type.startsWith('image/') || file.type.startsWith('video/')
        );
        const previewFiles = supportedFiles.map((file) => ({
            id: null,
            file,
            preview: URL.createObjectURL(file),
            name: file.name || ''
        }));
        setFiles((prevFiles) => {
            const updatedFiles = [...previewFiles, ...prevFiles];
            setCurrentSlide(previewFiles.length - 1);
            return updatedFiles;
        });
        onFilesChange([...supportedFiles, ...files.map((f) => f.file).filter(Boolean)]);
    };

    const handleRemoveFile = (index) => {
        const removedFile = files[index];
        if (removedFile.id) {
            const updatedRemovedFileIds = [...removedFileIds, removedFile.id];
            setRemovedFileIds(updatedRemovedFileIds);
            onRemovedFiles(updatedRemovedFileIds);
        }

        const updatedFiles = files.filter((_, i) => i !== index);
        setFiles(updatedFiles);
        onFilesChange(updatedFiles.map((f) => f.file).filter(Boolean));

        if (currentSlide >= updatedFiles.length && updatedFiles.length > 0) {
            setCurrentSlide(updatedFiles.length - 1);
        }
    };

    const handleDragOver = (event) => event.preventDefault();

    const handleNext = () => {
        if (currentSlide < files.length - 1) {
            setCurrentSlide((prev) => prev + 1);
        }
    };

    const handlePrev = () => {
        if (currentSlide > 0) {
            setCurrentSlide((prev) => prev - 1);
        }
    };

    return (
        <Box py={2}>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                    border: '2px dashed grey',
                    borderRadius: '8px',
                    padding: '20px',
                    textAlign: 'center',
                    cursor: 'pointer',
                }}
                onDrop={handleFileDrop}
                onDragOver={handleDragOver}
                onClick={() => fileInputRef.current.click()}
            >
                <UploadIcon fontSize="large" />
                <Typography variant="body2">
                    Drag & Drop files here or click to browse
                </Typography>
            </Box>
            <input
                multiple
                type="file"
                style={{ display: 'none' }}
                onChange={handleFileSelect}
                ref={fileInputRef}
                accept="image/*,video/*"
            />

            {files.length > 0 && (
                <Box position="relative" textAlign="center" mt={2}>
                    <PrevArrow onClick={handlePrev} disabled={currentSlide === 0} />
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        {files.map((fileObj, index) => (
                            index === currentSlide && (
                                <Fragment key={index}>
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            padding: 1,
                                            textAlign: 'center',
                                            flexGrow: 1,
                                        }}
                                        display="flex"
                                    >
                                        {fileObj.file?.type?.startsWith('image/') ? (
                                            <img
                                                src={fileObj.preview}
                                                alt="Preview"
                                                style={{
                                                    width: '100%',
                                                    height: '200px',
                                                    objectFit: 'cover',
                                                    borderRadius: '8px',
                                                }}
                                                loading="lazy"
                                            />

                                        ) : fileObj.file?.type?.startsWith('video/') ? (
                                            <video
                                                src={fileObj.preview}
                                                controls
                                                style={{
                                                    width: '100%',
                                                    height: '200px',
                                                    borderRadius: '8px',
                                                }}
                                            />
                                        ) : (
                                            <img
                                                src={fileObj.preview}
                                                alt="Preview"
                                                style={{
                                                    width: '100%',
                                                    height: '200px',
                                                    objectFit: 'cover',
                                                    borderRadius: '8px',
                                                }}
                                                loading="lazy"
                                            />

                                        )}
                                        <IconButton
                                            onClick={() => handleRemoveFile(index)}
                                            sx={{ position: 'absolute', top: 8, right: 8, color: '#FFF', backgroundColor: 'rgba(0,0,0,0.5) !important' }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                    <Typography color="text.secondary" display="inline" variant="body2">
                                        {fileObj.name}
                                    </Typography>
                                </Fragment>
                            )
                        ))}
                    </Box>
                    <NextArrow onClick={handleNext} disabled={currentSlide === files.length - 1} />
                </Box>
            )}
        </Box>
    );
};

export default UploadFiles;
