import React from 'react'
import { Box, Container } from '@mui/material'
import PageHeader from '../../components/PageHeader';
import NotificationsIcon from '@mui/icons-material/Notifications';


function Notifications() {
    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 8,
                px: 1
            }}
        >
            <Container maxWidth="xl">
                <PageHeader title='Notifications' icon={<NotificationsIcon/>} />
                <Box style={{ textAlign: 'center' }} py={16}>
                    <h1>No notifications yet</h1>
                    <p>you will receive your notifications here</p>
                </Box>
            </Container>
        </Box>
    )
}

export default Notifications;