import React from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Box, Paper } from '@mui/material';

const ChannelList = ({ channels, handleFacebookConnect }) => {

  return (
    <Box sx={{ marginTop: 4 }}>
      <Box display="flex" sx={{ justifyContent: 'flex-end' }}>
        <Button variant="contained" color="primary" onClick={() => { handleFacebookConnect() }}>
          Connect Facebook
        </Button>
      </Box>

      <TableContainer component={Paper} sx={{ marginTop: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='left'><Typography variant="body1" fontWeight="bold">Channel Name</Typography></TableCell>
              <TableCell align='center'><Typography variant="body1" fontWeight="bold">Channel Type</Typography></TableCell>
              <TableCell align='center'><Typography variant="body1" fontWeight="bold">Linked Product</Typography></TableCell>
            </TableRow>
          </TableHead>
          
          <TableBody>
            {channels.map((channel, index) => (
              <TableRow key={channel?.attributes.channel_id}>
                <TableCell align='left'>{channel?.attributes.display_name}</TableCell>
                <TableCell align='center'>{channel?.attributes.channel_type || '-'}</TableCell>
                <TableCell align='center'>{channel?.attributes?.product.data?.attributes?.name || '-'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ChannelList;
