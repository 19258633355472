import { Box, Stack, Typography } from '@mui/material'
import React from 'react'

function PageHeader({ icon, title = '', rightContent }) {
    return (

        <Box
            sx={{
                width:'100%',
                display: 'flex',
                mt:2
            }}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                spacing={4}
                sx={{
                    width:'100%'
                }}
            >
                <Box component="div" sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    {icon}
                    <Typography color="inherit" variant="h6" sx={{ ml: 1 }}>
                        {title}
                    </Typography>
                </Box>
                <div>
                    {rightContent}
                </div>
            </Stack>
        </Box>
    )
}

export default PageHeader;