import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Typography, Box, Paper } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';


const ProductList = ({ products, onEdit, onDelete, handleBtnClick }) => {
  return (
    <Box sx={{ flexGrow: 1,  }}>
      <Box display="flex" sx={{ marginTop: 4, justifyContent: 'flex-end' }}>
        <Button variant="contained" color="primary" onClick={handleBtnClick}>
          Create Product
        </Button>
      </Box>

      <TableContainer component={Paper} sx={{ marginTop: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='left'><Typography variant="body1" fontWeight="bold">Product</Typography></TableCell>
              {/* <TableCell><Typography variant="body1" fontWeight="bold">Details</Typography></TableCell>
              <TableCell><Typography variant="body1" fontWeight="bold">URL</Typography></TableCell> */}
              <TableCell align='center'><Typography variant="body1" fontWeight="bold">Channels</Typography></TableCell>
              <TableCell align='center'><Typography variant="body1" fontWeight="bold">Actions</Typography></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {products.length === 0 && <TableRow><TableCell colSpan={3}><Typography variant="body1" fontWeight="bold" sx={{py:4, textAlign:'center'}}>Products not available. create products to proceed further.</Typography></TableCell></TableRow>}
            {products.map((product) => (
              <TableRow key={product.id}>
                <TableCell align='left'>{product?.attributes.name}</TableCell>
                {/* <TableCell>{product?.attributes.details}</TableCell>
                <TableCell>
                  <a href={product?.attributes.url} target="_blank" rel="noopener noreferrer">
                    {product?.attributes.url}
                  </a>
                </TableCell> */}
                <TableCell align='center'>
                  {product?.attributes.channels?.data.length > 0 ? (product?.attributes.channels?.data.map((channel) => (
                    <Typography key={channel.id} variant="body2">
                      {channel.attributes.display_name} - ({channel.attributes.channel_type})
                    </Typography>
                  ))) : '-'}
                </TableCell>
                <TableCell align='center'>
                  <Tooltip title="Edit Product">
                    <IconButton variant="contained" onClick={() => onEdit(product)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Product">
                    <IconButton variant="contained" onClick={() => onDelete(product)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ProductList;
