import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Tabs as MuiTabs, Tab, Container } from '@mui/material';
import PageHeader from './PageHeader';
import { removeNonValueFilters } from '../helpers';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0.5 }}>{children}</Box>}
    </div>
  );
}

function Tabs(props) {
  const { tabs, defaultView = '', pageTitle, pageIcon, showPageHeader = false, pageHeadRight } = props;
  const [queryParams, setQueryParams] = useSearchParams();
  const existingParams = Object.fromEntries([...queryParams]);
  const initialView = existingParams?.view || defaultView;
  const initialIndex = tabs.findIndex((tab) => tab.key === initialView);
  const [value, setValue] = useState(initialIndex === -1 ? 0 : initialIndex);

  const updateQueryParams = (params) => {
    const newParams = removeNonValueFilters({ ...existingParams, ...params });
    setQueryParams(newParams);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    updateQueryParams({ view: tabs[newValue].key });
  };

  useEffect(() => {
    const currentIndex = tabs.findIndex((tab) => tab.key === existingParams?.view);
    if (currentIndex !== -1 && currentIndex !== value) {
      setValue(currentIndex);
    }
  }, [existingParams, tabs, value]);


  useEffect(() => {
    if (!existingParams?.view) {
      updateQueryParams({ view: defaultView });
    }
  }, [existingParams, defaultView, updateQueryParams]);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
        px: 1,
      }}
    >
      <Container maxWidth="xl">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 2,
            flexWrap: 'wrap',
          }}
        >
          {showPageHeader && (
            <PageHeader title={pageTitle} icon={pageIcon} rightContent={pageHeadRight} />
          )}
        </Box>
        <Box sx={{ width: '100%' }}>
          <MuiTabs value={value} onChange={handleTabChange} sx={{mx:1.5}}>
            {tabs.map((tab, index) => (
              <Tab key={index} label={tab.label} value={index} />
            ))}
          </MuiTabs>
          {tabs.map((tab, index) => (
            <TabPanel key={index} value={value} index={index}>
              {tab.content}
            </TabPanel>
          ))}
        </Box>
      </Container>
    </Box>
  );
}

export default Tabs;
