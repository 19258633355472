import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box } from '@mui/material';
import { transformText } from '../helpers';

export default function SamplePrompt({ socialMedia, numOfDays }) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'sample-prompt' : undefined;
    const content = `Provide a ${transformText(socialMedia)} post for every day upto ${numOfDays} days for user growth for a mobile based card game, the card game is a strategy game.`;
    return (
        <div>
            <Tooltip title="Sample Prompt">
                <IconButton aria-describedby={id} variant="contained" onClick={handleClick}>
                    <InfoIcon />
                </IconButton>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box component="div" display="flex" alignItems="center" justifyContent="flex-end" >
                    <Typography sx={{ p: 2 }}>{content}</Typography>
                    <Tooltip title="Copy Prompt">
                        <IconButton
                            sx={{ mr: 1 }}
                            variant="contained" 
                            onClick={() => {
                                navigator.clipboard.writeText(content);
                                handleClose();
                            }}
                        >
                            <ContentCopyIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Popover>
        </div>
    );
}
