import React, { useState, useEffect } from 'react';
import { TextField, Button, Box } from '@mui/material';
import MUIAutocomplete from '../../components/MUIAutoComplete';
import UploadFiles from './UploadFiles';

const ProductForm = ({ product, onSubmit }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [url, setUrl] = useState('');
    const [channels, setChannels] = useState([]);
    const [files, setFiles] = useState([]);
    const [removedFiles, setRemovedFiles] = useState([]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const productData = {
            name,
            description,
            url,
            channels,
            files,
            removedFiles
        };
        onSubmit(productData);
    };

    useEffect(() => {
        if (product) {
            setName(product.attributes.name || '');
            setDescription(product.attributes.details || '');
            setUrl(product.attributes.url || '');
            setChannels(
                product.attributes.channels.data.map((cItem) => ({
                    id: cItem.id,
                    label: `${cItem.attributes['display_name']} - ${cItem.attributes.channel_type}`,
                    data: cItem,
                }))
            );
        }
    }, [product]);

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                margin: 'auto',
                paddingTop: 1,
            }}
        >
            <TextField
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
            />
            <TextField
                label="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                multiline
                rows={4}
                required
            />
            <TextField
                label="URL"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                type="url"
                required
            />
            <MUIAutocomplete
                multiple
                label="Channels"
                selectedValue={channels}
                apiRequest={{
                    url: `/api/channels?populate=*&publicationState=preview`,
                    method: 'GET',
                }}
                fieldKey="display_name"
                onSelect={(channelObj) => setChannels(channelObj)}
                autoHighlight
            />
            <UploadFiles 
                onFilesChange={setFiles} 
                existingMedia={product?.attributes?.media?.data || []}
                onRemovedFiles={setRemovedFiles}
            />
            <Button type="submit" variant="contained" color="primary">
                {product ? 'Update Product' : 'Create Product'}
            </Button>
        </Box>
    );
};

export default ProductForm;
