import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import AppRoutes from "./routes/Routes";
import { useAuthContext } from "./context/AuthContext";
import { Navigate } from "react-router-dom";
import { Layout } from "./theme/Layout";
import SignIn from "./pages/SignIn/SignIn";
import SignUp from "./pages/SignUp/SignUp";
import NotFound from "./pages/NotFound";
import PublicRoute from "./routes/PublicRoute";
import { useScrollToTop } from "./hooks/useScrollToTop";

export const AuthRedirect = () => {
  const { user, isLoading } = useAuthContext();

  useEffect(() => {
    const path = window.location.pathname;

    if (isLoading) return;

    if (!user && path === "/") {
      <Navigate to="/login" replace />;
    }
    if (user && (path === "/" || path === "/login")) {
      <Navigate to="/dashboard" replace />;
    }
  }, [user, isLoading]);

  return null;
};

export default function App() {
  const { user, isLoading } = useAuthContext();
  useScrollToTop();
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={<PublicRoute restricted={true} element={<SignIn />} />}
        />
        <Route
          path="/signup"
          element={<PublicRoute restricted={true} element={<SignUp />} />}
        />
        <Route
          path="/login"
          element={<PublicRoute restricted={true} element={<SignIn />} />}
        />
      </Routes>
      {user && <Layout isAuthenticated={user}>
        <AppRoutes />
      </Layout>}
    </>
  );
}
